import BlockContent from '@sanity/block-content-to-react';
import React from 'react';
import Button from '@/components/common/Button';
import serializer from '@/components/utils/serializers/title';
import Icon from '@/components/common/Icon';

interface Props {
  backgroundVideoMobile?: any;
  backgroundVideo?: any;
  backgroundImages?: any;
  theme?: string;
  style?: {
    paddingTop: boolean;
    paddingBottom: boolean;
  };
  align?: string;
  title?: string;
  fullscreen?: boolean;
  label?: any;
  text?: any;
  ctas?: any[];
  logos?: any[];
  children?: any;
}

const alignClass = (align: string): string => {
  switch (align) {
    case 'left':
      return 'items-start text-left';
    case 'right':
      return 'items-end text-left';
    default:
      return 'items-center text-center';
  }
};

const themeClass = (theme: string): string => {
  switch (theme) {
    case 'white':
      return 'bg-white text-black';
    case 'green':
      return 'bg-green text-white';
    default:
      return 'bg-black text-white';
  }
};

const Hero = ({
  backgroundVideoMobile,
  backgroundVideo,
  backgroundImages,
  theme = 'black',
  align,
  title,
  label,
  text,
  ctas,
  logos,
  fullscreen = false,
  style = {
    paddingBottom: true,
    paddingTop: true,
  },
  children,
}: Props): JSX.Element => {
  const renderBackground = (): JSX.Element => {
    if (backgroundVideo || backgroundVideoMobile) {
      return (
        <div className="bg-black absolute top-0 left-0 w-full h-full z-0">
          <video
            className="w-full h-full object-cover opacity-30"
            muted
            autoPlay
            playsInline
            loop
            src={(backgroundVideo || backgroundVideoMobile).asset.url}
          />
        </div>
      );
    }

    if (backgroundImages && backgroundImages.length > 0 && backgroundImages[0].asset?.url) {
      return (
        <div className="bg-black absolute top-0 left-0 w-full h-full z-0">
          <img
            alt={backgroundImages[0].title || ''}
            className="w-full h-full object-cover opacity-30"
            src={backgroundImages[0].asset.url + '?fm=jpg&w=1440&q=85'}
          />
        </div>
      );
    }

    return null;
  };

  return (
    <div
      className={`relative flex items-center flex-col ${
        fullscreen == true ? `min-h-[100vh]` : ''
      } ${themeClass(
        backgroundImages || backgroundVideo || backgroundVideoMobile ? 'black' : theme
      )}`}>
      {renderBackground()}

      <div
        className={`w-full relative items-center justify-center flex-1 flex flex-col justify-center pt-[8rem] ${
          style?.paddingBottom ? 'pb-[5rem] md:pb-[10rem]' : 'pb-[2rem]'
        } ${style?.paddingTop ? 'md:pt-[15rem]' : ''} px-4 sm:px-12 lg:px-24`}>
        <div className={`flex flex-col gap-5 md:gap-10 w-full max-w-[1440px] ${alignClass(align)}`}>
          {label && label}
          {title && (
              <h1 className={`max-w-[${align == 'center' ? '900px' : '800px'}]`}>
                {typeof title == 'string' ? (
                  <div className="gap-5 flex flex-col">{title}</div>
                ) : (
                  <BlockContent serializers={serializer} blocks={title} />
                )}
            </h1>
          )}
          {text && (
              <div className={`max-w-[${align == 'center' ? '800px' : '600px'}]`}>
                {typeof text == 'string' ? (
                  <div className="gap-5 flex flex-col">{text}</div>
                ) : (
                  <BlockContent className="gap-5 flex flex-col" blocks={text} />
                )}
            </div>
          )}
          {ctas?.length > 0 && (
              <div
                className={`flex flex-row flex-wrap gap-x-4 gap-y-4 max-w-[800px] ${
                  align !== 'center' ? 'justify-start' : 'justify-center w-full'
                }`}>
                {ctas.map((cta) => (
                  <Button key={cta.title} className="flex-1 sm:flex-none" {...cta} />
                ))}
            </div>
          )}
          {children}
          {fullscreen && <Icon className="absolute bottom-8 animate-bounce" type="arrowdown" />}
        </div>
      </div>
      {logos && (
        <div className="pb-10 w-full flex justify-center px-4 sm:px-12 lg:px-24 opacity-50">
          <ul className="relative w-full max-w-[1440px] grid grid-cols-4 gap-8 lg:flex lg:justify-between">
            {logos.map((l, index) => (
              <li key={index} className="flex max-w-[7rem] items-center">
                <img className="max-h-10 h-auto object-contain" src={l.asset.url} alt="" />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Hero;
