import React, { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { MarketerRealEstateLogo } from 'components/icons/Logo';
import Close from 'components/icons/navigation/Close';
import Hamburger from 'components/icons/navigation/Hamburger';
import Button from '@/components/common/Button';
import Icon from '@/components/common/Icon';
import { useRouter } from 'next/router';

export interface Props {
  siteConfig?: any;
  theme?: 'dark' | 'light';
  isLandingpage?: boolean;
}

const Dropdown = ({ title, links, url }: any) => {
  const router = useRouter();

  const current =
    links.some((link) => {
      const linkUrl = link.url || link.route.elements.slug.current;

      return (router.asPath == '/' && linkUrl == '/home') || (linkUrl == router.asPath) == true;
    }) ||
    (router.asPath == '/' && url == '/home') ||
    url == router.asPath;

  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const listener = (e: any): void => {
      if (!ref.current || ref.current.contains(e.target)) {
        return;
      }

      setOpen(false);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref]);

  return (
    <li className="relative" ref={ref}>
      <button
        onClick={() => setOpen(!open)}
        className={`font-heading px-2 h-10 font-medium flex-row flex items-center gap-1 rounded-md hover:bg-[rgba(100,100,100,0.5)] ${
          current ? 'bg-[rgba(100,100,100,0.5)]' : ''
        }`}>
        {title}
        <span className={`transition-all duration-300	${open ? 'rotate-180' : ''}`}>
          <Icon type="caretdown" className="w-6" />
        </span>
      </button>
      <ul
        className={`bg-[#f4f4f4] shadow-md text-black flex ${
          open ? `` : `hidden`
        } py-4 rounded-md flex group-focus:flex flex-col absolute left-0`}>
        {links.map((link) => {
          const linkUrl = link.url || link.route.elements.slug.current;
          const linkTitle = link.title || link.route.elements.title;

          return (
            <li key={link._key} className="flex">
              <Link href={`/${linkUrl}`} passHref legacyBehavior>
                <a
                  onClick={() => setOpen(false)}
                  role="presentation"
                  className={`px-6 ${
                    (router.asPath == '/' && linkUrl == '/home') || linkUrl == router.asPath
                      ? 'bg-[rgba(100,100,100,0.2)]'
                      : ''
                  } hover:bg-primaryHover py-2 w-full flex-nowrap whitespace-nowrap`}>
                  {linkTitle}
                </a>
              </Link>
            </li>
          );
        })}
      </ul>
    </li>
  );
};

const Header = ({ siteConfig, theme, isLandingpage = false }: Props): JSX.Element => {
  const [open, setOpen] = useState(false);
  const { navigation, navigationCta } = siteConfig;
  const [fixed, setFixed] = useState(false);

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : '';

    return () => {
      document.body.style.overflow = '';
    };
  }, [open]);

  useEffect(() => {
    const onScroll = (e: any): void => {
      setFixed(e.target.documentElement.scrollTop > 10);
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
      setFixed(false);
    };
  }, []);

  const router = useRouter();

  return (
    <div
      className={`${theme == 'dark' ? 'text-white' : 'text-black'} border-b ${
        fixed
          ? theme == 'light'
            ? 'bg-white border-[#f4f4f4]'
            : 'bg-black border-[#222]'
          : 'border-transparent'
        } transition-all fixed flex flex-col items-center top-0 left-0 w-full z-20 px-4 lg:px-10 pt-12`}>
      <div className='w-full fixed top-0'><Link className="w-full bg-primary bg-opacity-50 text-white flex items-center justify-center h-12 text-center" href="https://marketer.tech/">{'Part of Marketer Technologies – Visit'} <Icon type="externalarrow" className='w-4 ml-2' /></Link></div>
      <nav
        className={`py-4 ${
          fixed ? 'md:py-4' : 'lg:py-10'
        } transition-all w-full max-w-[1440px] flex gap-4 flex-row justify-between items-center`}>
        <span className="flex flex-1 md:flex-none">
          <Link href="/" passHref legacyBehavior>
            <a
              aria-label="Go to frontpage"
              className="h-10 rounded-md flex justify-center hover:bg-[rgba(100,100,100,0.5)] px-2">
              <MarketerRealEstateLogo className="w-[16rem] h-auto" />
            </a>
          </Link>
        </span>

        {!isLandingpage && navigation?.length > 0 && (
          <ul className="gap-4 hidden md:flex items-center">
            {navigation.map((navItem) => {
              const current =
                (router.asPath == '/' && navItem.url == '/home') || navItem.url == router.asPath;

              if (navItem.links?.length > 0) {
                return <Dropdown key={navItem._key} {...navItem} />;
              }

              const linkUrl = navItem.url || navItem.route.elements.slug.current;
              const linkTitle = navItem.title || navItem.route.elements.title;

              return (
                <li key={navItem._key}>
                  <Link href={`/${linkUrl}`} passHref legacyBehavior>
                    <a
                      className={`font-heading flex items rounded-md items-center font-medium px-2 h-10 ${
                        current ? 'bg-[rgba(100,100,100,0.5)]' : ''
                      } hover:bg-[rgba(100,100,100,0.5)]`}>
                      {linkTitle}
                    </a>
                  </Link>
                </li>
              );
            })}
          </ul>
        )}

        <ul className="flex justify-center gap-4 h-full">
          {!isLandingpage &&
            navigationCta &&
            navigationCta.length > 0 &&
            navigationCta.map((cta: any) => (
              <li className="hidden lg:inline-flex" key={cta._key}>
                <Button {...cta} route={cta.route.elements.slug.current} />
              </li>
            ))}

          <li className="language-switcher"> </li>

          <li className="inline-flex justify-center items-center">
            <button
              className={`md:hidden cursor-pointer ${open ? 'text-white' : ''} relative z-10`}
              onClick={() => setOpen(!open)}
              aria-label={open ? 'Close menu' : 'Open menu'}>
              {open ? <Close /> : <Hamburger />}
            </button>
          </li>
        </ul>

        {!isLandingpage && navigation?.length > 0 && (
          <ul
            className={`fixed right-0 top-0 bottom-0 left-0 py-16 px-4 sm:px-12 overflow-scroll scrolling-touch text-left bg-black gap-4 text-white ${
              open ? 'flex' : 'hidden'
            } flex-col gap-2 md:hidden`}>
            {navigation.map((navItem) => {
              if (navItem.links?.length > 0) {
                return (
                  <li key={navItem._key} className="relative">
                    <span className="text-xs tracking-wider font-medium text-left uppercase opacity-50">
                      {navItem.title}
                    </span>
                    <ul className="flex pl-4 py-2 rounded-md flex-col">
                      {navItem.links.map((link) => {
                        const linkUrl = link.url || link.route.elements.slug.current;
                        const linkTitle = link.title || link.route.elements.title;

                        return (
                          <li key={link._key} className="flex">
                            <Link href={`/${linkUrl}`} legacyBehavior>
                              <a
                                onClick={() => setOpen(false)}
                                onKeyDown={(e) => e.key == 'Enter' && setOpen(false)}
                                role="presentation"
                                className="hover:bg-primary font-heading font-medium py-2 w-full flex-nowrap whitespace-nowrap ">
                                {linkTitle}
                              </a>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                );
              }

              const linkUrl = navItem.url || navItem.route.elements.slug.current;
              const linkTitle = navItem.title || navItem.route.elements.title;

              return (
                <li key={navItem._key} className="relative">
                  <span className="text-xs tracking-wider font-medium text-left uppercase opacity-50">
                    {navItem.title}
                  </span>
                  <ul className="flex pl-4 py-2 rounded-md flex-col">
                    <li key={navItem._key} className="text-center">
                      <Link href={`/${linkUrl}`} passHref legacyBehavior>
                        <a
                          onClick={() => setOpen(false)}
                          onKeyDown={(e) => e.key == 'Enter' && setOpen(false)}
                          role="presentation"
                          className="font-heading font-medium text-left py-2 flex justify-start">
                          {linkTitle}
                        </a>
                      </Link>
                    </li>
                  </ul>
                </li>
              );
            })}
          </ul>
        )}
      </nav>
    </div>
  );
};

export default Header;
