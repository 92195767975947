import React from 'react';

export enum Themes {
  LIGHT = 'light',
  DARK = 'dark',
}

type State = Themes;

type ThemeProviderProps = {
  theme: Themes;
  children: React.ReactNode;
};

export const ThemeContext = React.createContext<State | undefined>(undefined);

export function ThemeProvider({ theme = Themes.DARK, children }: ThemeProviderProps): any {
  return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>;
}

export function useTheme(): any {
  const context = React.useContext(ThemeContext);

  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }

  return context;
}
