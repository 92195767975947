import React from 'react';
import Head from 'next/head';
import Hero from '../plugs/Hero';

const FourOhFour = (): JSX.Element => {
  return (
    <>
      <Head>
        <title>404</title>
      </Head>
      <Hero
        title="404"
        text={'Did not find the page you were looking for.'}
        ctas={[
          {
            title: 'Go back to homepage',
            theme: 'whiteOutline',
            size: 'large',
            route: '',
          },
        ]}
      />
    </>
  );
};

export default FourOhFour;
