import React from 'react';

const Hamburger = (): JSX.Element => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 20H27" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5 12H27" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default Hamburger;