import React from "react";

interface Node {
  children?: any;
}

const serializers = {
  types: {
    block: ({ children }: Node): JSX.Element => {
      return <span>{children}</span>;
    },
  },
  marks: {
    highlight: ({ children }: Node): JSX.Element => {
      return <span className="text-primary">{children}</span>;
    },
  },
};

export default serializers;
