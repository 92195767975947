import Button from '@/components/common/Button';
import Icon from '@/components/common/Icon';
import React, { useState } from 'react';
import md5 from 'md5';
import axios from 'axios';
import TagManager from 'react-gtm-module';

interface Props {
  isLandingpage?: boolean;
}

const Footer = ({ isLandingpage = false }: Props): JSX.Element => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const validateEmail = (email: string, allowEmpty: boolean): boolean => {
    if (
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      ) ||
      (allowEmpty && email.length <= 0)
    ) {
      setError(false);
      return true;
    }

    setError('Email not valid');
    return false;
  };

  const onSubmit = async (e: any): Promise<void> => {
    e.preventDefault();

    const email = e.nativeEvent.target.elements.email.value.toLowerCase();

    const subscriberHash = md5(email);

    try {
      if (validateEmail(email, false) == false) {
        return;
      }

      await axios.put(
        `/api/mailchimp/lists/${process.env.NEXT_PUBLIC_NEWSLETTER_AUDIENCE_ID}/members/${subscriberHash}`,
        {
          email_address: email,
          status: 'subscribed',
          language: navigator.language,
        },
        {
          auth: {
            username: 'user',
            password: process.env.NEXT_PUBLIC_NEWSLETTER_API_TOKEN,
          },
        }
      );

      TagManager.dataLayer({
        dataLayer: {
          event: 'sign_up_for_newsletter_submitted',
          email_address: email,
          language: navigator.language,
          fromUrl: window.location.href,
        },
      });

      e.nativeEvent.target.elements.email.value = '';
      setSuccess(true);

      setTimeout(() => {
        setSuccess(false);
      }, 3000);
    } catch (err) {
      console.log(err);
      setError(err.message);
    }
  };

  return (
    <footer className="bg-black flex justify-center text-white flex border-t-[1px] border-solid border-[#333] py-[5rem] md:py-[5rem] px-[2rem]">
      <div className="w-full max-w-[1440px] flex items-center flex-col gap-10 md:gap-20">
        <div
          className={`flex flex-col md:flex-row gap-10 md:gap-20 w-full justify-center ${
            !isLandingpage ?? 'md:justify-between'
          } max-w-[800px]`}>
          {!isLandingpage && (
            <div className="flex flex-col text-center md:items-start gap-5">
              <p>Join our newsletter and stay up to date!</p>
              <form className="flex justify-center gap-5 relative" onSubmit={onSubmit}>
                <input
                  className={`rounded-md flex-1 md:w-[20rem] h-10 bg-[#222] border-[1px] border-solid border-[#444] pl-2 ${
                    error ? 'border-[#E7583B]' : ''
                  }`}
                  type="text"
                  name="email"
                  onInput={(e) => {
                    const target = e.nativeEvent.target as HTMLInputElement;
                    validateEmail(target.value, false);
                  }}
                  onBlur={(e) => validateEmail(e.target.value, true)}
                  placeholder="Enter your email"
                />
                <Button type="submit" noRoute theme="whiteOutline">
                  Subscribe
                </Button>
                {error && (
                  <p
                    className="bg-[#E7583B] text-white text-sm rounded-md px-1 absolute
              left-0 bottom-[-2rem]">
                    {error}
                  </p>
                )}
                {success && (
                  <p
                    className="bg-primary text-black text-sm rounded-md px-1 absolute
              left-0 bottom-[-2rem]">
                    Great, you are on the list!
                  </p>
                )}
              </form>
            </div>
          )}
          <div className="flex flex-col items-center text-center gap-5">
            <p>Follow us!</p>
            <div className="flex gap-5">
              <a href="https://www.facebook.com/marketertech">
                <Icon type="facebook" />
              </a>
              <a href="https://www.linkedin.com/company/marketertechnologies/">
                <Icon type="linkedin" />
              </a>
              <a href="https://www.instagram.com/marketertechnologies/">
                <Icon type="instagram" />
              </a>
            </div>
          </div>
        </div>
        <div className="text-center flex flex-col gap-5">
          <div>
            <span className="whitespace-nowrap">{`Copyright © ${new Date().getFullYear()}`}</span> •{' '}
            <span className="whitespace-nowrap">{`Marketer Real Estate Technologies AS`}</span> •{' '}
            <span className="whitespace-nowrap">{`Organisation number: 922 540 535`}</span>
          </div>
          <div className="flex justify-center">
            <Button route="privacy-policy" theme="link">
              Privacy policy
            </Button>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
