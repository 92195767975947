import React from "react";

export const MarketerRealEstateLogo = ({
  className
}: {
  className?: string
}): JSX.Element => (
  <svg className={className} width="724" height="51" viewBox="0 0 724 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M114.328 1.74852H121.979L137.91 42.2529L153.762 1.74852H161.413V50.2119H155.684V11.5988L140.46 50.2119H135.281L120.057 11.5988V50.2119H114.328V1.74852Z" fill="currentColor" />
    <path d="M199.199 27.7138V50.2119H193.666V44.1835C191.822 48.2024 187.192 50.7635 181.816 50.7635C174.518 50.7635 169.771 46.6264 169.771 40.2434C169.771 33.5846 175.225 29.3687 183.621 29.3687H191.469C192.999 29.3687 193.666 28.6201 193.666 27.5168C193.666 22.7493 190.292 19.479 184.367 19.479C179.266 19.479 175.264 22.6705 174.989 26.453H169.731C170.398 19.7548 176.716 14.7903 184.524 14.7903C193.548 14.7903 199.199 19.9518 199.199 27.7138ZM193.666 34.4908V33.5846H183.935C178.756 33.5846 175.381 36.0669 175.381 40.1646C175.381 43.7501 178.207 46.0353 182.601 46.0353C189.625 46.0747 193.666 41.7012 193.666 34.4908Z" fill="currentColor" />
    <path d="M221.878 20.2273C214.854 20.2273 212.853 26.6103 212.853 34.8452V50.2116H207.321V15.3416H212.853V22.3944C214.305 17.6269 217.209 15.3416 222.309 15.3416H226.704V20.2668H221.878V20.2273Z" fill="currentColor" />
    <path d="M242.949 32.7573L238.397 36.8944V50.1726H232.825V1H238.397V30.078L254.759 15.342H262.057L247.186 28.9748L263.47 50.212H256.525L242.949 32.7573Z" fill="currentColor" />
    <path d="M298.234 38.9825C297.057 46.1141 290.936 50.7635 282.304 50.7635C272.024 50.7635 265.157 42.5286 265.157 32.5602C265.157 22.3159 271.514 14.7903 281.715 14.7903C291.839 14.7903 297.921 21.8431 297.921 31.1417C297.921 31.8903 297.842 33.0724 297.764 33.5452H270.611C271.043 40.7556 275.791 45.8777 282.343 45.8777C287.993 45.8777 291.564 43.3955 292.702 39.0219H298.234V38.9825ZM270.886 29.0929H292.27C291.839 23.2221 287.915 19.479 281.715 19.479C275.83 19.479 271.906 22.9463 270.886 29.0929Z" fill="currentColor" />
    <path d="M314.244 4.19162V15.3421H324.916V20.2673H314.244V39.0222C314.244 42.8835 316.441 45.1688 319.815 45.1688H324.916V50.2121H319.619C312.988 50.2121 308.711 46.075 308.711 39.2586V20.2279H301.491V15.3421H308.711V4.19162H314.244Z" fill="currentColor" />
    <path d="M361.054 38.9825C359.877 46.1141 353.756 50.7635 345.123 50.7635C334.843 50.7635 327.976 42.5286 327.976 32.5602C327.976 22.3159 334.333 14.7903 344.535 14.7903C354.658 14.7903 360.74 21.8431 360.74 31.1417C360.74 31.8903 360.661 33.0724 360.583 33.5452H333.431C333.862 40.7556 338.61 45.8777 345.163 45.8777C350.813 45.8777 354.383 43.3955 355.521 39.0219H361.054V38.9825ZM333.666 29.0929H355.05C354.619 23.2221 350.695 19.479 344.495 19.479C338.649 19.479 334.725 22.9463 333.666 29.0929Z" fill="currentColor" />
    <path d="M382.674 20.2273C375.65 20.2273 373.649 26.6103 373.649 34.8452V50.2116H368.156V15.3416H373.649V22.3944C375.101 17.6269 378.004 15.3416 383.105 15.3416H387.5V20.2668H382.674V20.2273Z" fill="currentColor" />
    <path d="M27.7065 9.53709L26.1219 8.66911L7.74447 42.4984L9.32901 43.3663L27.7065 9.53709Z" fill="currentColor" />
    <path d="M64.5491 9.54636L62.9646 8.67837L44.5871 42.5076L46.1716 43.3756L64.5491 9.54636Z" fill="currentColor" />
    <path d="M44.5509 43.3921L46.1354 42.5242L27.7768 8.7295L26.1922 9.59748L44.5509 43.3921Z" fill="currentColor" />
    <path d="M81.3358 43.3888L82.9204 42.5208L64.5617 8.72612L62.9771 9.5941L81.3358 43.3888Z" fill="currentColor" />
    <path d="M26.9461 17.194C31.3885 17.194 34.9898 13.5777 34.9898 9.11675C34.9898 4.65582 31.3885 1.03952 26.9461 1.03952C22.5037 1.03952 18.9024 4.65582 18.9024 9.11675C18.9024 13.5777 22.5037 17.194 26.9461 17.194Z" fill="currentColor" />
    <path d="M63.7509 17.194C68.1934 17.194 71.7946 13.5777 71.7946 9.11675C71.7946 4.65582 68.1934 1.03952 63.7509 1.03952C59.3085 1.03952 55.7072 4.65582 55.7072 9.11675C55.7072 13.5777 59.3085 17.194 63.7509 17.194Z" fill="currentColor" />
    <path d="M82.1926 50.9999C86.635 50.9999 90.2363 47.3836 90.2363 42.9227C90.2363 38.4617 86.635 34.8454 82.1926 34.8454C77.7502 34.8454 74.1489 38.4617 74.1489 42.9227C74.1489 47.3836 77.7502 50.9999 82.1926 50.9999Z" fill="currentColor" />
    <path d="M45.3486 50.9999C49.791 50.9999 53.3923 47.3836 53.3923 42.9227C53.3923 38.4617 49.791 34.8454 45.3486 34.8454C40.9061 34.8454 37.3049 38.4617 37.3049 42.9227C37.3049 47.3836 40.9061 50.9999 45.3486 50.9999Z" fill="currentColor" />
    <path d="M8.5437 50.9999C12.9861 50.9999 16.5874 47.3836 16.5874 42.9227C16.5874 38.4617 12.9861 34.8454 8.5437 34.8454C4.10129 34.8454 0.5 38.4617 0.5 42.9227C0.5 47.3836 4.10129 50.9999 8.5437 50.9999Z" fill="currentColor" />
    <g opacity="0.5">
      <path d="M716.604 38.894H723.297C722.055 46.07 715.776 50.762 707.22 50.762C696.456 50.762 689.763 42.275 689.763 32.615C689.763 22.886 695.835 14.882 706.53 14.882C717.294 14.882 723.021 22.541 723.021 31.373C723.021 32.27 722.952 33.374 722.883 33.995H696.318C696.87 40.55 701.148 45.035 707.22 45.035C712.326 45.035 715.638 42.827 716.604 38.894ZM706.53 20.264C701.079 20.264 697.56 23.3 696.525 29.096H716.259C715.845 23.783 712.257 20.264 706.53 20.264Z" fill="currentColor" />
      <path d="M669.891 4.53201H676.584V15.434H686.865V21.23H676.584V38.618C676.584 42.344 678.585 44.414 681.966 44.414H686.865V50.21H681.414C674.376 50.21 669.891 45.932 669.891 38.825V21.23H662.784V15.434H669.891V4.53201Z" fill="currentColor" />
      <path d="M643.568 14.882C652.538 14.882 658.472 20.057 658.472 27.785V50.21H651.779V44.552C650.123 48.278 645.707 50.762 640.463 50.762C633.356 50.762 628.664 46.484 628.664 40.274C628.664 33.581 633.977 29.441 642.188 29.441H649.433C651.02 29.441 651.779 28.613 651.779 27.371C651.779 23.162 648.743 20.264 643.361 20.264C638.531 20.264 635.012 23.3 634.805 26.819H628.595C629.147 19.988 635.357 14.882 643.568 14.882ZM641.774 45.449C648.122 45.449 651.779 41.378 651.779 34.961V34.133H642.809C638.255 34.133 635.288 36.479 635.288 40.136C635.288 43.31 637.91 45.449 641.774 45.449Z" fill="currentColor" />
      <path d="M608.235 4.53201H614.928V15.434H625.209V21.23H614.928V38.618C614.928 42.344 616.929 44.414 620.31 44.414H625.209V50.21H619.758C612.72 50.21 608.235 45.932 608.235 38.825V21.23H601.128V15.434H608.235V4.53201Z" fill="currentColor" />
      <path d="M584.709 50.762C576.567 50.762 571.116 46.208 570.633 38.894H576.912C577.326 42.896 580.224 45.173 584.709 45.173C588.711 45.173 591.264 43.034 591.264 40.481C591.264 31.235 571.53 39.722 571.53 24.887C571.53 18.884 576.636 14.882 583.881 14.882C591.747 14.882 597.129 19.229 597.474 25.784H591.195C590.712 21.782 586.848 20.195 583.881 20.195C580.293 20.195 577.878 21.989 577.878 24.68C577.878 33.305 597.75 24.473 597.75 40.343C597.75 46.691 592.575 50.762 584.709 50.762Z" fill="currentColor" />
      <path d="M534.372 50.21V1.91H565.353V8.258H541.548V22.127H560.799V28.475H541.548V43.862H566.112V50.21H534.372Z" fill="currentColor" />
      <path d="M518.194 50.21V0.875H524.887V50.21H518.194Z" fill="currentColor" />
      <path d="M495.191 14.882C504.161 14.882 510.095 20.057 510.095 27.785V50.21H503.402V44.552C501.746 48.278 497.33 50.762 492.086 50.762C484.979 50.762 480.287 46.484 480.287 40.274C480.287 33.581 485.6 29.441 493.811 29.441H501.056C502.643 29.441 503.402 28.613 503.402 27.371C503.402 23.162 500.366 20.264 494.984 20.264C490.154 20.264 486.635 23.3 486.428 26.819H480.218C480.77 19.988 486.98 14.882 495.191 14.882ZM493.397 45.449C499.745 45.449 503.402 41.378 503.402 34.961V34.133H494.432C489.878 34.133 486.911 36.479 486.911 40.136C486.911 43.31 489.533 45.449 493.397 45.449Z" fill="currentColor" />
      <path d="M468.568 38.894H475.261C474.019 46.07 467.74 50.762 459.184 50.762C448.42 50.762 441.727 42.275 441.727 32.615C441.727 22.886 447.799 14.882 458.494 14.882C469.258 14.882 474.985 22.541 474.985 31.373C474.985 32.27 474.916 33.374 474.847 33.995H448.282C448.834 40.55 453.112 45.035 459.184 45.035C464.29 45.035 467.602 42.827 468.568 38.894ZM458.494 20.264C453.043 20.264 449.524 23.3 448.489 29.096H468.223C467.809 23.783 464.221 20.264 458.494 20.264Z" fill="currentColor" />
      <path d="M428.618 27.302C432.137 29.372 434.414 33.167 434.69 37.859L435.656 50.21H428.687L427.721 38.48C427.376 34.202 424.547 31.166 420.407 31.166H408.332V50.21H401.156V1.91H420.062C429.791 1.91 435.725 7.77501 435.725 16.4C435.725 21.575 432.896 25.163 428.618 27.302ZM408.332 8.12001V24.818H419.855C426.134 24.818 428.549 20.747 428.549 16.4C428.549 11.984 426.134 8.12001 419.855 8.12001H408.332Z" fill="currentColor" />
    </g>
  </svg>
)

export const MarketerLogo = ({
  className,
  vertical
}: {
  className?: string
  vertical?: boolean
}): JSX.Element => {
  if (vertical) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 96 64"
        className={className}>
        <path
          fill="currentColor"
          d="M0 46.79h2.695l5.602 14.227 5.576-14.227h2.695v17.024H14.56V50.237L9.182 63.801H7.36L2.01 50.237v13.564H0V46.79zm29.82 9.109V63.8h-1.942v-2.12c-.66 1.405-2.273 2.307-4.162 2.307-2.563 0-4.241-1.459-4.241-3.7 0-2.333 1.916-3.818 4.875-3.818h2.761c.529 0 .78-.265.78-.663 0-1.684-1.19-2.824-3.277-2.824-1.797 0-3.197 1.114-3.29 2.453h-1.836c.212-2.36 2.444-4.11 5.18-4.11 3.157.026 5.152 1.856 5.152 4.574zm-1.942 2.386v-.318h-3.422c-1.823 0-3 .875-3 2.307 0 1.26.992 2.069 2.55 2.069 2.445 0 3.872-1.525 3.872-4.058zm9.909-5.011c-2.47 0-3.171 2.24-3.171 5.13v5.397h-1.942V51.55h1.942v2.48c.515-1.684 1.533-2.48 3.316-2.48h1.546v1.724h-1.691zm7.412 4.401l-1.599 1.46v4.667h-1.968V46.525H43.6v10.21l5.747-5.185h2.564l-5.232 4.786 5.72 7.465h-2.444L45.2 57.675zm19.435 2.188C64.224 62.37 62.071 64 59.032 64c-3.607 0-6.038-2.89-6.038-6.39 0-3.594 2.233-6.246 5.813-6.246 3.568 0 5.695 2.48 5.695 5.741 0 .266-.026.677-.053.849h-9.54c.146 2.532 1.824 4.322 4.123 4.322 1.982 0 3.25-.875 3.634-2.413h1.968zm-9.618-3.474h7.504c-.145-2.068-1.533-3.38-3.713-3.38-2.047 0-3.435 1.22-3.791 3.38zm15.234-8.75v3.911h3.752v1.724h-3.753v6.59c0 1.365.78 2.16 1.969 2.16h1.797v1.777h-1.863c-2.325 0-3.832-1.458-3.832-3.845v-6.682h-2.55V51.55h2.55v-3.91h1.93zm16.448 12.224C86.288 62.37 84.135 64 81.096 64c-3.607 0-6.038-2.89-6.038-6.39 0-3.594 2.233-6.246 5.814-6.246 3.567 0 5.694 2.48 5.694 5.741 0 .266-.026.677-.053.849h-9.539c.145 2.532 1.823 4.322 4.122 4.322 1.982 0 3.25-.875 3.634-2.413h1.968zM77.08 56.39h7.504c-.145-2.068-1.532-3.38-3.712-3.38-2.048 0-3.422 1.22-3.792 3.38zm17.229-3.116c-2.47 0-3.171 2.24-3.171 5.13v5.397h-1.942V51.55h1.942v2.48c.515-1.684 1.533-2.48 3.316-2.48H96v1.724h-1.69zM35.834 5.744l-1.067-.585-12.453 22.908 1.067.584L35.834 5.744zm24.944.001L59.71 5.16 47.258 28.068l1.068.584L60.778 5.745z"
        />
        <path
          fill="currentColor"
          d="M47.252 28.645l1.067-.584L35.885 5.19l-1.067.584 12.434 22.872zm24.912 0l1.067-.585L60.798 5.188l-1.067.584 12.433 22.873z"
        />
        <path
          fill="currentColor"
          d="M35.33 10.925c3.006 0 5.443-2.445 5.443-5.462S38.335 0 35.329 0s-5.443 2.446-5.443 5.463 2.437 5.462 5.443 5.462zm24.931 0c3.006 0 5.443-2.445 5.443-5.462S63.267 0 60.261 0s-5.443 2.446-5.443 5.463 2.437 5.462 5.443 5.462zM72.733 33.81c3.006 0 5.443-2.446 5.443-5.463s-2.437-5.462-5.443-5.462-5.443 2.445-5.443 5.462 2.437 5.463 5.443 5.463zm-24.932 0c3.007 0 5.444-2.446 5.444-5.463s-2.437-5.462-5.444-5.462c-3.006 0-5.443 2.445-5.443 5.462s2.437 5.463 5.443 5.463zm-24.944 0c3.006 0 5.443-2.446 5.443-5.463s-2.437-5.462-5.443-5.462-5.443 2.445-5.443 5.462 2.437 5.463 5.443 5.463z"
        />
      </svg>
    );
  }

  return (
    <svg
      viewBox="0 0 108 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path
        d="M31.766.21H33.9l4.446 11.34L42.77.21h2.135v13.57h-1.599V2.967L39.06 13.779h-1.446L33.365 2.968v10.811h-1.6V.21zM55.45 7.48v6.3h-1.543V12.09c-.515 1.126-1.807 1.843-3.307 1.843-2.037 0-3.362-1.159-3.362-2.946 0-1.864 1.522-3.045 3.866-3.045h2.19c.427 0 .613-.21.613-.518 0-1.335-.942-2.25-2.595-2.25-1.424 0-2.54.893-2.617 1.952h-1.468c.186-1.876 1.95-3.266 4.128-3.266 2.519 0 4.096 1.446 4.096 3.619zm-1.543 1.897v-.253H51.19c-1.445 0-2.387.695-2.387 1.842 0 1.004.788 1.644 2.015 1.644 1.96.011 3.088-1.214 3.088-3.233zM61.78 5.384c-1.96 0-2.519 1.787-2.519 4.093v4.302h-1.544V4.016h1.544V5.99c.405-1.335 1.216-1.974 2.64-1.974h1.226v1.379H61.78v-.011zM67.66 8.892l-1.27 1.158v3.718h-1.555V0h1.555v8.142l4.566-4.126h2.037l-4.15 3.817 4.544 5.946h-1.938L67.66 8.892zM83.088 10.635c-.328 1.997-2.036 3.299-4.445 3.299-2.87 0-4.785-2.306-4.785-5.097 0-2.869 1.774-4.976 4.62-4.976 2.826 0 4.523 1.975 4.523 4.579 0 .21-.022.54-.044.673H75.38c.12 2.019 1.445 3.453 3.274 3.453 1.577 0 2.573-.695 2.89-1.92h1.544v-.01zm-7.632-2.769h5.968c-.12-1.644-1.215-2.692-2.945-2.692-1.643 0-2.738.971-3.023 2.692zM87.556.894v3.122h2.979v1.379h-2.979v5.251c0 1.081.614 1.721 1.555 1.721h1.424v1.412h-1.478c-1.851 0-3.045-1.158-3.045-3.067V5.384h-2.014V4.016h2.014V.894h1.544zM100.62 10.635c-.329 1.997-2.037 3.299-4.446 3.299-2.87 0-4.785-2.306-4.785-5.097 0-2.869 1.774-4.976 4.62-4.976 2.826 0 4.523 1.975 4.523 4.579 0 .21-.022.54-.044.673h-7.577c.12 2.019 1.445 3.453 3.274 3.453 1.577 0 2.573-.695 2.89-1.92h1.545v-.01zm-7.644-2.769h5.968C98.824 6.222 97.73 5.174 96 5.174c-1.632 0-2.727.971-3.023 2.692zM106.653 5.384c-1.96 0-2.518 1.787-2.518 4.093v4.302h-1.533V4.016h1.533V5.99c.405-1.335 1.215-1.974 2.639-1.974H108v1.379h-1.347v-.011zM7.593 2.39l-.443-.243-5.128 9.473.442.243L7.593 2.39zM17.874 2.393l-.442-.243-5.129 9.472.442.243 5.13-9.472z"
        fill="currentColor"
      />
      <path
        d="M12.293 11.87l.442-.243-5.123-9.463-.442.243 5.123 9.463zM22.559 11.869l.442-.243-5.123-9.463-.443.243 5.124 9.463z"
        fill="currentColor"
      />
      <path
        d="M7.38 4.534a2.253 2.253 0 002.245-2.261A2.253 2.253 0 007.38.01a2.253 2.253 0 00-2.244 2.262A2.253 2.253 0 007.38 4.534zM17.651 4.534a2.253 2.253 0 002.245-2.261A2.253 2.253 0 0017.651.01a2.253 2.253 0 00-2.244 2.262 2.253 2.253 0 002.244 2.261zM22.798 14a2.253 2.253 0 002.245-2.262 2.253 2.253 0 00-2.245-2.261 2.253 2.253 0 00-2.245 2.261A2.253 2.253 0 0022.798 14zM12.516 14a2.253 2.253 0 002.245-2.262 2.253 2.253 0 00-2.245-2.261 2.253 2.253 0 00-2.245 2.261A2.253 2.253 0 0012.516 14zM2.245 14a2.253 2.253 0 002.245-2.262 2.253 2.253 0 00-2.245-2.261A2.253 2.253 0 000 11.738 2.253 2.253 0 002.245 14z"
        fill="currentColor"
      />
    </svg>
  );
};