import React from 'react';
import { useTheme } from '../context/theme';
import Footer from './Footer';
import Header from './Header';

const Layout = ({ theme, children, siteConfig, isLandingpage = false }: {
  siteConfig: any;
  theme?: 'dark' | 'light';
  children: React.ReactNode;
  isLandingpage?: boolean;
}): JSX.Element => {
  const importedTheme = useTheme();
  const finalTheme = theme || importedTheme;

  return (
    <>
      <Header siteConfig={siteConfig} theme={finalTheme} isLandingpage={isLandingpage} />
      {children}
      <Footer isLandingpage={isLandingpage} />
      {/* <ScrollBack /> */}
    </>
  );
};

export default Layout;
